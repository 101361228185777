<template>
  <div class="skmain">
    <div class="container">
      <div class="wrapper">
        <div class="hole">
          <div class="space-bottom">
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
          </div>
          <div class="space-top">
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
          </div>
          <div class="space-left">
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
          </div>
          <div class="space-right">
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container2">
      <div class="wrapper2">
        <div class="hole2">
          <div class="space-bottom">
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
          </div>
          <div class="space-top">
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
          </div>
          <div class="space-left">
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
          </div>
          <div class="space-right">
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container3">
      <div class="wrapper3">
        <div class="hole3">
          <div class="space-bottom">
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
          </div>
          <div class="space-top">
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
          </div>
          <div class="space-left">
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
          </div>
          <div class="space-right">
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container4">
      <div class="wrapper4">
        <div class="hole4">
          <div class="space-bottom">
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
            <div class="star-bottom"></div>
          </div>
          <div class="space-top">
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
            <div class="star-top"></div>
          </div>
          <div class="space-left">
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
            <div class="star-left"></div>
          </div>
          <div class="space-right">
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
            <div class="star-right"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="content" v-show="isshow">
      <p class="animated fadeIn">信号已连接</p>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const isshow = ref(false);
    onMounted(() => {
      setTimeout(function () {
        isshow.value = true;
      }, 3000);
      setTimeout(function () {
        router.push("/newhome");
      }, 5000);
    });

    return {
      isshow,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./style/sk.scss";
.skmain {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: black;
  .content {
    font-size: 20px;
    color: #d5d8e2;
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    top: 50%;
  }
}
</style>
